import * as React from "react"
import Demo from "../../components/Demo"
import { UnityContext } from "react-unity-webgl"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet"

const unityContextSmokeFusion = new UnityContext({
  loaderUrl: "/games/smokefusion/smoke-fusion-among-us.loader.js",
  dataUrl: "/games/smokefusion/smoke-fusion-among-us.data",
  frameworkUrl: "/games/smokefusion/smoke-fusion-among-us.framework.js",
  codeUrl: "/games/smokefusion/smoke-fusion-among-us.wasm",
})

function handleOnClickFullscreen() {
  unityContextSmokeFusion.setFullscreen(true)
}

function SmokefusionGame() {
  const [progression, setProgression] = useState(0)

  useEffect(function () {
    unityContextSmokeFusion.on("progress", function (progression) {
      setProgression(progression)
    })
  }, [])

  return (
    <>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <meta charSet="utf-8" />
        <title>OakGames - Smoke Fusion</title>
        <meta
          name="description"
          content="Smoke Fusion - an amazing game for your company"
        />
      </Helmet>
      <Demo
        progression={progression}
        unityContextGame={unityContextSmokeFusion}
        isSmokeFusion={true}
        handleFullScreen={handleOnClickFullscreen}
      />
    </>
  )
}

export default SmokefusionGame
